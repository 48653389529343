import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const Navigation = (props) => {
  const {
    firebase,
    authUser,
  } = props;

  const userSignedIn = !!authUser;

  console.log(!!authUser);

  return <div>
    <div>
      <ul>
        {!userSignedIn && <li>
          <Link to={ROUTES.SIGN_IN}>Sign In</Link>
        </li>}
        <li>
          <Link to={ROUTES.LANDING}>Landing</Link>
        </li>
        {userSignedIn && <React.Fragment>
          <li>
            <Link to={ROUTES.HOME}>Home</Link>
          </li>
          <li>
            <Link to={ROUTES.ACCOUNT}>Account</Link>
          </li>
          <li>
            <Link to={ROUTES.ADMIN}>Admin</Link>
          </li>
        </React.Fragment>}
      </ul>
    </div>
    {userSignedIn && <div>
      <Link to={ROUTES.LANDING} onClick={firebase.doSignOut}>Sign Out</Link>
    </div>}
  </div>
};

export default withAuthentication(Navigation);