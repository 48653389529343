import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { withFirebase } from '../Firebase';

const SignIn = (props) => {
  const {
    firebase
  } = props;

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/home',
    // We will display Email as auth providers.
    signInOptions: [
      firebase.authProviders.EmailAuthProvider.PROVIDER_ID,
    ]
  }

  return <div>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth} />
  </div>
}

export default withFirebase(SignIn);