// import _ from 'lodash';`
import React from 'react';
import { withFirebase } from '../Firebase';

const Home = (props) => {

  return <div>
    <h1>Home</h1>
  </div>
};

export default withFirebase(Home);