// import React, { useState, useEffect } from 'react';
import React from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

// const withAuthentication = Component => props => {
//     const {
//         firebase,
//     } = props;

//     const [authUser, setAuthUser] = useState(null);

//     useEffect(() => {
//         firebase.auth.onAuthStateChanged(authUser => {
//             authUser ? setAuthUser(authUser) : setAuthUser(null);
//         })
//     });

//     return <AuthUserContext.Provider value={authUser}>
//         <Component {...props} />
//     </AuthUserContext.Provider>;
// };

// export default withFirebase(withAuthentication);

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
      constructor(props) {
        super(props);
   
        this.state = {
          authUser: null,
        };
      }
   
      componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
          authUser => {
            authUser
              ? this.setState({ authUser })
              : this.setState({ authUser: null });
          },
        );
      }
   
      componentWillUnmount() {
        this.listener();
      }
   
      render() {
        return (
          <AuthUserContext.Provider value={this.state.authUser}>
            <Component {...this.props} authUser={this.state.authUser} />
          </AuthUserContext.Provider>
        );
      }
    }
   
    return withFirebase(WithAuthentication);
  };
   
  export default withAuthentication;