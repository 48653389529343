import _ from 'lodash';
import React from 'react';
import { withFirebase } from '../Firebase';
 
const Account = (props) => {
  const {
    firebase,
  } = props;
  
  return <div>
    <h1>Account</h1>
    <h3>{_.get(firebase, 'auth.currentUser.displayName')}</h3>
    <p>{_.get(firebase, 'auth.currentUser.metadata.lastSignInTime')}</p>
  </div>
};
 
export default withFirebase(Account);